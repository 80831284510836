import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardExpandToggler } from '../../other/card/card'
import CampaignCard from '../../other/card/campaignCard'
import ComponentLoader from '../../other/ComponentLoader'
import { getTopCampaignsQuery } from '../../../redux/apis/analyticsApiGeneral'
import { Link } from 'react-router-dom'

const TopCampaignsGeneral = ({ id }) => {
  const dispatch = useDispatch()
  const [currentYear, setCurrentYear] = useState(true)
  const { isLoading, topCampaignsQuery } = useSelector(
    state => state.analyticsGeneral
  )

  useEffect(() => {
    if (!id) return
    if (!topCampaignsQuery) {
      dispatch(getTopCampaignsQuery({ id: id }))
    }
  }, [id])

  return (
    <div className='col-xl-12 col-lg-6 '>
      <Card className='mb-3 min-h-[382px]'>
        <CardBody className='relative p-0'>
          {isLoading ? <ComponentLoader /> : null}
          <div className='p-4'>
            <div className='d-flex fw-bold small mb-3'>
              <span className='flex-grow-1'>Top Campaings</span>
              <CardExpandToggler />
            </div>
            <div className='mb-3 flex gap-3'>
              <button
                className={`btn ${
                  currentYear ? 'btn-outline-theme' : 'btn-outline-secondary'
                }`}
                onClick={() => setCurrentYear(true)}
              >
                Current Year
              </button>
              <button
                className={`btn ${
                  !currentYear ? 'btn-outline-theme' : 'btn-outline-secondary'
                }`}
                onClick={() => setCurrentYear(false)}
              >
                previous year
              </button>
            </div>
            <div className='flex gap-4 flex-wrap justify-center'>
              {topCampaignsQuery &&
              topCampaignsQuery.filter(item =>
                currentYear ? !item.is_last_year : item.is_last_year
              ).length > 0 ? (
                topCampaignsQuery
                  .filter(item =>
                    currentYear ? !item.is_last_year : item.is_last_year
                  )
                  .map(item => (
                    <Link to={`/campaigns-analytics/general/${item?.campaign_id}`}>
                      <CampaignCard
                        type={'top'}
                        item={item}
                        key={item.campaign_name}
                      />
                    </Link>
                  ))
              ) : (
                <p>nodata</p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default TopCampaignsGeneral
